$shedkey1a : #499cfd; // top of gradient blue, default for some text
$shedkey1b : #1c60b1; // bottom of gradient blue
$shedkey1f : white;     // white foreground (text)
$shedkey2 : white;    // gray
$shedkey2f : #1c60b1;   // blue

.page-container {
  min-height: 100vh; 
  display: flex;
  flex-direction: column;
  background-color: white;

  .footer {
    flex: 1;
  }
  
  .footer {
    padding-top : 2em;
    color : white;
  }  
}

#pre-footer {
  flex-grow: 1;
}



div.svg {
  width : 100%;
  height : 100%;
}

div.svg > img  {
  max-width: 100%;
}


.shedkey.background {
  background:  $shedkey1a ; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(180deg, $shedkey1a 0%, $shedkey1b 100%); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(180deg, $shedkey1a 0%, $shedkey1b 100%); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(180deg, $shedkey1a 0%, $shedkey1b 100%); /* For Firefox 3.6 to 15 */
  background: linear-gradient(180deg, $shedkey1a 0%, $shedkey1b 100%); /* Standard syntax */
  color: $shedkey1f;
}

.shedkey.inverted {
  background-color : $shedkey1a;
  color: $shedkey1f;
}

.shedkey.secondary {
  background-color : $shedkey2;

  .header {
    color : $shedkey2f;
  }
  color: black;
}

.shedkey.foreground {
  color : $shedkey2f
}

.header-bar {
  padding: 0.5em;
  min-height : 79px;
}

.mini.pusher {
  padding-top : 20px;
}

.pusher {
  padding-top: 40px;
}

.large.pusher {
  padding-top: 70px;
}

.massive.pusher {
  padding-top: 140px;
}

.large-text{
  font-size: 1.8em;
}


.clickable {
  cursor: pointer;
}

.centered.form input {
  text-align: center;
}


#header-container {
  height: 5rem;

  div.svg img {
    height: 2rem;
  }
}

